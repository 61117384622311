import React from 'react'
import { graphql } from 'gatsby'
import {Link} from 'gatsby'
import ArrowDown from '../../images/arrow_right_black.inline.svg'
import {Styling} from '../../styles/pages/programmes-production.styled'
import {HiOutlineStatusOnline} from 'react-icons/hi'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import parse from 'html-react-parser'
import Seo from '../../components/global/seo'

export default function Programmes(props) {
  const programmes = props.data.programmes.nodes;
  const theImage = getImage(props?.data?.wpPage?.interactive_programme_background_image?.backgroundImage?.localFile);

    return (
        <Styling>
          <Seo title={props.data.wpPage.seo.title} description={props.data.wpPage.seo.metaDesc} />
          <div className="intro">
              <h2 className="main-title"><span>Toolroom</span> <span><ArrowDown className="arrow title-arrow"/> Programmes</span></h2>
              <div className="intro_text">{parse(props.data.wpPage.programme_archive_fields_production.introText)}</div>
              <div className="intro_buttons">
                <Link to="/programmes/interactive/" className={`capsule_button ${props.path==='/programmes/interactive/' ? 'black active' : 'white'}`}>Interactive Programmes</Link>
                <Link to="/programmes/on-demand/" className={`capsule_button ${props.path==='/programmes/on-demand/' ? 'black active' : 'white'}`}>On-Demand Tutorials</Link>
              </div>
          </div>
          <div className="programmes-wrapper">
            <ul className="programmes">
            {
                programmes.map((programme, index) => {
                    const programmeImage = getImage(programme?.interactive_programme?.programmeIcon?.localFile)
                    const title = programme.title
                    const titleSplit = title.split(" ");
                    const finalTitle = titleSplit.map(title => <span>{title}</span>)
                    return(
                        <li key={`programme_homepage_${index}`} className="featured_programme">
                            <Link to={`/programmes/interactive/${programme.slug}/`} className="featured_programme_link">
                            <div className="icon">
                                <GatsbyImage image={programmeImage} className="image" alt={'test'} />
                            </div>
                            <h2 className="programme_title">{finalTitle}</h2>
                            <div className="capsule"><HiOutlineStatusOnline /> Online</div>
                            <div className="programme_text">{programme.interactive_programme.archiveFieldsCopy}</div>
                            <ul className="programme_list">
                              {
                                programme.interactive_programme.archiveDetailList.map((list, index) => {
                                  return(
                                    <li>{list.detail}</li>
                                  )
                                })
                              }
                            </ul>
                            <div className="capsule_button black">{programme.interactive_programme.archiveButtonText}</div>
                            </Link>
                        </li>
                    )
                })
            }
            </ul>
            <div className="bg">
              <ParallaxProvider>
                  <Parallax y={[-20, 20]} tagOuter="figure">
                  <GatsbyImage image={theImage} className="image" alt={'test'} />
                  </Parallax>
              </ParallaxProvider>
              </div>
          </div>
        </Styling>
    )
}

export const query = graphql`
  {
    wpPage(title: {eq: "Interactive Programmes"}) {
        title
        seo{
          metaDesc
          title
        }
        programme_archive_fields_production {
          introText
        }
        interactive_programme_background_image {
          backgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 2500
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
    }
    programmes : allWpProgramme(
      sort: {fields: menuOrder, order: ASC}
      filter: {programmeCategories: {nodes: {elemMatch: {name: {eq: "Interactive"}}}}}
    )  {
      nodes {
        title
        slug
        interactive_programme {
          archiveButtonText
          archiveFieldsCopy
          archiveDetailList {
            detail
          }
          programmeIcon {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`